import toast, { Toaster } from "react-hot-toast";
import * as React from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useState } from "react";
import { EndSessionCommand, UserPreferencesClient } from "../../swagger";
import { axiosInstance } from "../../services/AxiosInstance";
import useGetLoginSessions from "../../swr/getLoginSessions";
import { mutate } from "swr";
import SimpleLoading from "../../components/simpleLoading";
import Loading from "../../components/loading";
import classNames from "classnames";
import { UAParser } from 'ua-parser-js';

const WhereYoureSignedIn = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { loginSessions, isLoading, isError } = useGetLoginSessions();
  const isMobile = window.innerWidth < 768;

  // React.useEffect(() => {
  //   const getLoginSessions = async () => {
  //     const client = new UserPreferencesClient(undefined, axiosInstance);
  //     setLoading(true);
  //     try {
  //       var res = await client.getLoginSessions();
  //       if (res) {
  //         setLoginSessions(res);
  //         setLoading(false);
  //       } else {
  //         setLoading(false);
  //       }
  //     } catch (error) {
  //       setLoading(false);
  //     }
  //   };

  //   getLoginSessions();
  // }, []);

  console.log("loginSessions", loginSessions);

  const handleClick = async (values) => {
    let command = { sessionId: values.id } as EndSessionCommand;
    const client = new UserPreferencesClient(undefined, axiosInstance);
    setLoading(true);

    try {
      if (values.terminable) {
        var res = await client.endSession(command);
        if (res) {
          toast.success(t("identity.profile.updated"));
          setLoading(false);
          mutate("userPreferences.getLoginSessions");
        } else {
          setLoading(false);
          toast.error(_.first(res.errors) as any);
        }
      }
    } catch (error) {
      setLoading(false);
      toast.error(
        t(
          "identity.src.components.login.EnterEmail.somethingUnexpectedHappenedPleaseTryAgainLater"
        )
      );
    }
  };

  const getAgent = (ua: string) => {
    var parser = new UAParser(ua);
    var result = parser.getResult();
    return `${result.browser.name} ${result.browser.version} on ${result.os.name} ${result.os.version}`;
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Toaster position="top-center" reverseOrder={true}></Toaster>
          <div className="space-y-6  md:col-span-9 md:px-0">
            <div
              className={classNames("bg-white pt-6 overflow-hidden ", {
                "rounded-[15px] shadow-[0_3px_5px_0_rgba(9,30,66,0.10)]":
                  !isMobile,
              })}
            >
              <div className="px-4 sm:px-6">
                <h2 className="text-lg font-medium leading-6 text-gray-900">
                  {t("identity.profile.WhereYoureSignedIn")}
                </h2>
              </div>

              <div className="mt-6 flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full align-middle sm:px-6 lg:px-8">
                    <div className="overflow-hidden border-t border-gray-200">
                      <div className="p-6 grid grid-cols-1 gap-y-6">
                        <div className="flex flex-col space-y-1">
                          <p className="text-base font-[600] leading-6 text-gray-900">
                            {t("identity.profile.ActiveSessions")}
                          </p>
                          <div className="w-full h-fit p-2 text-[rgba(0,0,0,0.75)] text-sm font-[400] leading-[1.5]  rounded-md border-[1px] border-solid border-[rgba(0,0,0,0.08)] bg-[#F9FAFB]">
                            The locations listed below are an estimate of where
                            the IP address may be located within your country,
                            region and city. The accuracy of the look-up varies
                            by providers and the location of the device. This
                            should only be used as a rough guideline.
                          </div>
                        </div>

                        <div className="w-full h-fit flex flex-col space-y-5 ">
                          <p
                            className={`${
                              loginSessions
                                ? "font-[400] text-[rgba(0,0,0,0.9)] text-lg "
                                : "hidden"
                            }  `}
                          >
                            You're signed in to {loginSessions?.length} sessions
                          </p>

                          {loginSessions
                            ?.filter((e) => e.currentSession)
                            .map((e) => (
                              <React.Fragment key={e.id}>
                                <div className="flex flex-col space-y-3 border-b-[1px] border-[rgba(0,0,0,0.08)] pb-5">
                                  <p className="font-[600] text-[rgba(0,0,0,0.9)] text-base">
                                    Current Session
                                  </p>
                                  <p className="font-[500] text-[rgba(0,0,0,0.9)] text-base">
                                    Details
                                  </p>
                                  <p className="font-[400] text-[rgba(0,0,0,0.9)] text-sm">
                                    ip : {e.ip}
                                  </p>
                                  <div className="flex flex-col space-y-2">
                                    <p className="font-[400] text-[rgba(0,0,0,0.9)] text-sm">
                                      {getAgent(e.userAgent)}
                                    </p>
                                    {e.ipInfo !== undefined ? (
                                      <>
                                        <div className="flex flex-col space-y-2">
                                          {e.ipInfo.city?
                                          <p className="font-[400] text-[rgba(0,0,0,0.9)] text-sm">
                                            city : {e.ipInfo.city}
                                          </p>:null}
                                          {e.ipInfo.continent?
                                          <p className="font-[400] text-[rgba(0,0,0,0.9)] text-sm">
                                            continent : {e.ipInfo.continent}
                                          </p>
                                          :null}
                                          {e.ipInfo.country?
                                          <p className="font-[400] text-[rgba(0,0,0,0.9)] text-sm">
                                            country : {e.ipInfo.country}
                                          </p>
                                          :null}
                                          {e.ipInfo.region?
                                          <p className="font-[400] text-[rgba(0,0,0,0.9)] text-sm">
                                            region : {e.ipInfo.region}
                                          </p>
                                          :null}
                                        </div>
                                      </>
                                    ) : (
                                      " "
                                    )}
                                    <p className="font-[400] text-[rgba(0,0,0,0.9)] text-sm">
                                      {e.updateDate
                                        ? e.updateDate.toLocaleString() : e.createDate
                                        ? e.createDate.toLocaleString()
                                        : "No date available"}
                                    </p>
                                  </div>
                                </div>
                              </React.Fragment>
                            ))}

                          {loginSessions?.length !== 0 && loginSessions ? (
                            <p className="font-[600] text-[rgba(0,0,0,0.9)] text-base">
                              Other active sessions
                            </p>
                          ) : (
                            <Loading />
                          )}

                          {loginSessions
                            ?.filter((e) => !e.currentSession)
                            .map((e) => {
                              const createDate = new Date(e.createDate);
                              const currentDate = new Date();
                              const timeDifference =
                                currentDate.getTime() - createDate.getTime();
                              const daysPassed = Math.floor(
                                timeDifference / (1000 * 60 * 60 * 24)
                              );

                              return (
                                <React.Fragment key={e.id}>
                                  <div className="flex flex-col space-y-3 border-b-[1px] border-[rgba(0,0,0,0.08)] pb-5">
                                    <div className="flex flex-row justify-between">
                                      <div className="">
                                        Last accessed:{" "}
                                        {daysPassed == 0
                                          ? "today"
                                          : `${daysPassed} days ago`}
                                      </div>
                                      {e.terminable ? (
                                        <div
                                          onClick={() => handleClick(e)}
                                          className={`text-[#0073b1] font-[600] text-base cursor-pointer px-2 py-1 rounded-md hover:bg-[rgba(152,216,244,0.25)] `}
                                        >
                                          End
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    <p className="font-[500] text-[rgba(0,0,0,0.9)] text-base">
                                      Details
                                    </p>
                                    <div className="flex flex-col space-y-2">
                                      <p className="font-[400] text-[rgba(0,0,0,0.9)] text-sm">
                                        {getAgent(e.userAgent)}
                                      </p>
                                      {e.ipInfo !== undefined ? (
                                        <>
                                          <div className="flex flex-col space-y-2">
                                            <p className="font-[400] text-[rgba(0,0,0,0.9)] text-sm">
                                              City : {e.ipInfo.city}
                                            </p>
                                            <p className="font-[400] text-[rgba(0,0,0,0.9)] text-sm">
                                              Continent : {e.ipInfo.continent}
                                            </p>
                                            <p className="font-[400] text-[rgba(0,0,0,0.9)] text-sm">
                                              Country : {e.ipInfo.country}
                                            </p>
                                            <p className="font-[400] text-[rgba(0,0,0,0.9)] text-sm">
                                              Region : {e.ipInfo.region}
                                            </p>
                                          </div>
                                        </>
                                      ) : (
                                        " "
                                      )}
                                      <p className="font-[400] text-[rgba(0,0,0,0.9)] text-sm">
                                        {e.updateDate
                                          ? e.updateDate.toLocaleString(): e.createDate
                                          ? e.createDate.toLocaleString()
                                          : "No date available"}
                                      </p>
                                    </div>
                                  </div>
                                </React.Fragment>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default WhereYoureSignedIn;
